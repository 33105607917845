import Container from 'components/Container'
import { Paragraph } from 'components/Typography'
import { boxShadow, colors, spacings } from 'stylesheets/theme'
import Button, { Variant } from 'components/Button'
import FilterGroup from './FilterGroup'
import { IFilterItem, FilterOption } from './types'
import { css } from '@emotion/react'
import TrashIcon from 'images/icons/trash.svg'
import { IAudienceCountsByType } from './AudienceContext'

interface IAdvancedFilterComponentProps {
  filterGroups?: IFilterItem[][]
  addNewFilterItem?: (filterGroupIndex: number) => void
  removeFilterItem?: (filterGroupIndex: number, itemIndex: number) => void
  addFilterGroup?: () => void
  removeFilterGroup?: (groupIndex: any) => void
  onFilterItemChange?: (
    groupIndex: number,
    itemIndex: number,
    filterType: FilterOption,
    filterInputs: any,
  ) => void
  audienceCount: IAudienceCountsByType
  subscribersUrl?: string
  filtersUrl?: string
}

const filterGroupWrapperStyle = css({
  minHeight: 115,
  borderRadius: spacings.radius,
  backgroundColor: colors.backgrounds.white,
  boxShadow: boxShadow.preview,
  padding: spacings.grid_gap_basis_num * 2,
  width: '100%',
})

interface IEndIconListProps {
  groupIndex: number
  groupsCount: number
  trashButton: JSX.Element
}

function EndIconList({
  groupIndex,
  groupsCount,
  trashButton,
}: IEndIconListProps): JSX.Element {
  if (groupsCount === 1) {
    return null
  } else if (groupIndex !== groupsCount - 1) {
    return (
      <Container
        css={{ minWidth: 60, justifyContent: 'space-between', flex: 1 }}>
        <Paragraph>OR</Paragraph>
        {trashButton}
      </Container>
    )
  } else {
    return (
      <Container css={{ minWidth: 60, justifyContent: 'flex-end', flex: 1 }}>
        {trashButton}
      </Container>
    )
  }
}

export default function AdvancedFilterComponent({
  filterGroups = [],
  addNewFilterItem = () => {},
  removeFilterItem = () => {},
  addFilterGroup = () => {},
  removeFilterGroup = () => {},
  onFilterItemChange = () => {},
  audienceCount,
  subscribersUrl,
  filtersUrl,
}: IAdvancedFilterComponentProps): JSX.Element {
  return (
    <Container
      css={{
        gap: spacings.grid_gap_basis_num * 2,
        overflow: 'scroll',
        alignItems: 'flex-start',
        flexDirection: 'column',
        maxHeight: 600,
        minHeight: 350,
        paddingRight: spacings.grid_gap_basis_num * 2,
        overflowX: 'auto',
        boxSizing: 'content-box',
      }}>
      <Paragraph
        css={{
          color: colors.text.text_3,
        }}>
        <b>{audienceCount.filters.audienceCount.matched}</b> selected users
      </Paragraph>
      {filterGroups.map((filterGroup, groupIndex) => {
        const containerStyle =
          filterGroups.length === 1
            ? filterGroupWrapperStyle
            : [
                filterGroupWrapperStyle,
                css({
                  width: 990,
                }),
              ]
        return (
          <Container
            key={`filter-group-${groupIndex}`}
            css={{
              width: '100%',
            }}>
            <div css={containerStyle}>
              <FilterGroup
                groupIndex={groupIndex}
                filterGroup={filterGroup}
                addNewFilterItem={() => addNewFilterItem(groupIndex)}
                removeFilterItem={(itemIndex) =>
                  removeFilterItem(groupIndex, itemIndex)
                }
                onFilterItemChange={(itemIndex, filterType, filterInputs) =>
                  onFilterItemChange(
                    groupIndex,
                    itemIndex,
                    filterType,
                    filterInputs,
                  )
                }
                subscribersUrl={subscribersUrl}
                filtersUrl={filtersUrl}
              />
            </div>
            <EndIconList
              groupIndex={groupIndex}
              groupsCount={filterGroups.length}
              trashButton={
                <Button
                  type="Button"
                  variant={Variant.UNSTYLED}
                  onClick={() => removeFilterGroup(groupIndex)}>
                  <TrashIcon
                    css={{
                      width: 20,
                      height: 20,
                    }}
                  />
                </Button>
              }
            />
          </Container>
        )
      })}
      <Button variant={Variant.LINK} onClick={addFilterGroup} type="Button">
        + Add filter group
      </Button>
    </Container>
  )
}
