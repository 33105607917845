import React, { useCallback } from 'react'
import classNames from 'classnames'

import CommunityPicker from 'components/CommunityPicker'

import DeleteIcon from '../../../images/icons/delete.svg'

export interface ICohost {
  id: number | undefined
  community_id: number | undefined
  name: string | undefined
  _destroy?: boolean
  errors: Record<string, string[]> | undefined
}

interface ICohostRow {
  index: number
  rootEntity: string
  isFirst: boolean
  communitiesUrl: string
  value: ICohost | undefined
  onChange: (index: number, value: ICohost) => void
  communityId: number
}

const CohostRow = ({
  index,
  rootEntity,
  communitiesUrl,
  isFirst,
  value,
  onChange,
  communityId,
}: ICohostRow): React.ReactElement => {
  const onCommunityPickerChange = useCallback(
    (record) => {
      onChange(index, {
        ...value,
        name: record['community'].name,
        community_id: record['community'].id,
      })
    },
    [index, onChange, value],
  )

  const onDelete = useCallback(() => {
    onChange(index, {
      ...value,
      _destroy: true,
    })
  }, [index, onChange, value])

  return (
    <div
      className={classNames('cohost-row additional-section-row', {
        hidden: !!value._destroy,
        first: isFirst,
      })}>
      {(typeof value.id !== 'undefined' || value.id !== null) && (
        <input
          type="hidden"
          name={`${rootEntity}[cohosts_attributes][${index}][id]`}
          value={value.id}
        />
      )}
      {!!value._destroy && (
        <input
          type="hidden"
          name={`${rootEntity}[cohosts_attributes][${index}][_destroy]`}
          value="true"
        />
      )}
      <CommunityPicker
        name="community"
        inputName={`${rootEntity}[cohosts_attributes][${index}][community_id]`}
        communitiesUrl={communitiesUrl}
        value={{
          id: value.community_id,
          name: value.name,
        }}
        label={isFirst && 'Community'}
        onChange={onCommunityPickerChange}
        error={value.errors?.community?.join(', ')}
        wrapperClassName="one"
        communityId={communityId}
      />
      <button
        aria-label="remove cohost"
        className={classNames('not-styled-button delete', {
          first: isFirst,
        })}
        type="button"
        onClick={onDelete}>
        <DeleteIcon />
      </button>
    </div>
  )
}

export default CohostRow
