import { useTableContext } from '../TableContext'
import { ITableColumn, ITableRow } from 'components/Table'
import {
  convertUTCToLocalRange,
  serverUTCTimeFormat,
} from 'javascripts/general'
import moment from 'moment-timezone'

//assets
import RecurringIcon from '../../../images/icons/recurring_14.svg'

//components for cell
import EventAction from 'components/EventAction'
import CallAction from 'components/CallAction'
import { AdmitButton } from 'components/WaitlistTable'
import RegistrationStatusPill from 'components/RegistrationStatusPill'
import UserImportStatusPill from 'components/UserImportStatusPill'
import UserTableActionsCell from 'components/UserTableActionsCell'
import { Paragraph } from 'components/Typography'
import MentorshipExchangeStatusPill from 'components/MentorshipExchangeStatusPill/MentorshipExchangeStatusPill'
import {
  EditMatchReasoningCell,
  AcceptedMatchActionsCell,
  MatchReviewActionsCell,
  NotYetMatchedActionsCell,
  MatchesRemovedActionsCell,
  ApplicantRowActions,
  PrioritizationCell,
} from 'components/MentorshipExchangeDetails'
import CommunicationLogActionsCell from 'components/Audience/CommunicationLogActionsCell'
import ActivityLogActionsCell from 'components/Audience/ActivityLogActionsCell'
import CheckboxCell, { ICheckboxCellData } from './CheckboxCell'
import PendingManagerApprovalRowActions from 'components/MentorshipExchangeDetails/PendingManagerApprovalRowActions'
import MatchRowNotificaitonStatusPill from 'components/MentorshipExchangeDetails/MatchRowNotificationStatusPill'
import { css } from '@emotion/react'
import Button, {
  ButtonComponentProps,
  ButtonComponentType,
  Variant,
} from 'components/Button'
import LeadrArchetype from 'components/LeadrArchetype/LeadrArchetype'
import LeadrActionsCell from 'components/LeadrIndexOverview/LeadrActionsCell'

const tableCellSecondaryButtonStyle = css({
  padding: '4px 16px 4px 16px',
})

export function TableCellSecondaryButton({
  children,
  ...props
}: ButtonComponentProps<ButtonComponentType.BUTTON>): JSX.Element {
  return (
    <Button
      variant={Variant.SECONDARY}
      css={tableCellSecondaryButtonStyle}
      {...props}>
      {children}
    </Button>
  )
}

export function TableCellSecondaryLink({
  children,
  ...props
}: ButtonComponentProps<ButtonComponentType.LINK>): JSX.Element {
  return (
    <Button
      variant={Variant.SECONDARY}
      as={ButtonComponentType.LINK}
      css={tableCellSecondaryButtonStyle}
      {...props}>
      {children}
    </Button>
  )
}

const CellComponents = {
  'event-action-cell': EventAction,
  'cta-action-cell': CallAction,
  'admit-button': AdmitButton,
  'registration-status-cell': RegistrationStatusPill,
  'mentorship-exchange-status-cell': MentorshipExchangeStatusPill,
  'applicant-row-actions': ApplicantRowActions,
  'user-import-status-cell': UserImportStatusPill,
  'user-actions-cell': UserTableActionsCell,
  'edit-match-reasoning-cell': EditMatchReasoningCell,
  'match-review-actions-cell': MatchReviewActionsCell,
  'accepted-match-actions-cell': AcceptedMatchActionsCell,
  'not-yet-matched-actions-cell': NotYetMatchedActionsCell,
  'matches-removed-actions-cell': MatchesRemovedActionsCell,
  'prioritization-cell': PrioritizationCell,
  'communication-log-actions-cell': CommunicationLogActionsCell,
  'activity-log-actions-cell': ActivityLogActionsCell,
  'check-box-cell': CheckboxCell,
  'pending-manager-approval-row-actions': PendingManagerApprovalRowActions,
  'notification-status-cell': MatchRowNotificaitonStatusPill,
  'leadr-archetype-cell': ({ data }) => {
    const { leadr_profile } = data
    return <LeadrArchetype archetype={leadr_profile} small />
  },
  'leadr-actions-cell': LeadrActionsCell,
}

interface ITableCellProps<T> {
  column: ITableColumn
  link?: string
  value: string | number
  row: ITableRow<T>
}

const TableCell = <T,>({
  column,
  link,
  value,
  row,
}: ITableCellProps<T>): JSX.Element => {
  const { features, checkRow, uncheckRow, selectedRows } = useTableContext()

  const renderData = () => {
    let selectedOption

    switch (column.type) {
      case 'string':
      case 'number':
        return value === null ? '-' : value
      case 'date':
      case 'datetime':
        return value
      case 'datetime_utc_range':
      case 'time_utc':
        return (
          <>
            {convertUTCToLocalRange(
              value as string,
              (row.data as any).duration as number,
              column.type === 'time_utc',
            )}
            {Boolean((row.data as any).is_recurring) && (
              <RecurringIcon className="recurring-icon" />
            )}
          </>
        )
      case 'datetime_utc':
        return value
          ? moment(
              (value as string).replace('UTC', '+0000'),
              serverUTCTimeFormat,
            ).format('ddd, MMM Do, YYYY, h:mma')
          : ''
      case 'date_utc':
        return moment(
          (value as string).replace('UTC', '+0000'),
          serverUTCTimeFormat,
        ).format('ddd, MMM Do, YYYY')
      case 'date_no_year':
        return moment((value as string).replace('UTC', '+0000')).format(
          'ddd, MMM Do, h:mma',
        )
      case 'select':
        selectedOption = (column.options || []).find(
          (option) => option.value === value,
        )
        return column.options && selectedOption ? selectedOption.label : '–'
      case 'html':
        return <div dangerouslySetInnerHTML={{ __html: value as string }} />
      case 'title-bold':
        return <Paragraph bold>{value}</Paragraph>
      case 'react': {
        const CellComponent = CellComponents[column.component]
        if (!CellComponent) return '-'

        if (column.component == 'check-box-cell') {
          console.log('row.data', row.data)
          return (
            <CheckboxCell
              data={{
                ...(row.data as ICheckboxCellData),
                checked: selectedRows.find(
                  (selectedRow) =>
                    selectedRow.id == (row.data as ICheckboxCellData).id,
                ),
              }}
              onCheck={checkRow}
              onUncheck={uncheckRow}
            />
          )
        }

        return (
          <CellComponent
            features={features}
            data={row.data}
            className="table-cell"
          />
        )
      }
    }
  }

  const classNames = [`column-type-${column.type}`]
  if (column.class_name) {
    classNames.push(column.class_name)
  }

  return (
    <td className={classNames.join(' ')}>
      {link && !column.ignore_click ? (
        <a href={link} className="GojiCustomTable__cell-value">
          {renderData()}
        </a>
      ) : (
        <div className="GojiCustomTable__cell-value">{renderData()}</div>
      )}
    </td>
  )
}

export default TableCell
