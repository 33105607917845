import classNames from 'classnames'
import Slider from 'react-slick'

import { homepageSliderOptions } from 'components/helper-functions'
import Community from 'components/Community'

import GlobeIcon from 'images/icons/globe.svg'

import { SlickArrow } from './SlickArrow'

import type { ICommunity } from 'types'
import type { ITableData } from 'components/Table'
import PromotionCard, { PromotionCardType } from './PromotionCard'

interface CommunitiesSliderProps {
  orgName: string
  communitiesData: ITableData<ICommunity>
  subscriptions: boolean
  promotionType: PromotionCardType | null
  promotionEmail?: string
}

const sliderOptions = (slidesToShow: number) => ({
  ...homepageSliderOptions,
  draggable: false,
  nextArrow: <SlickArrow isNext aria-label="next" />,
  prevArrow: <SlickArrow isNext={false} aria-label="prev" />,
  slidesToShow: slidesToShow > 4 ? 4 : slidesToShow,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: slidesToShow > 4 ? 4 : slidesToShow,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: slidesToShow > 3 ? 3 : slidesToShow,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: slidesToShow > 2 ? 2 : slidesToShow,
      },
    },
    {
      breakpoint: 690,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
})

export default function CommunitiesSlider({
  orgName,
  communitiesData,
  subscriptions,
  promotionType,
  promotionEmail,
}: CommunitiesSliderProps): JSX.Element {
  const numCommunities = communitiesData.rows.length
  const showPromotion = promotionType && !subscriptions // && numCommunities < 4

  return (
    <div
      className={classNames('communities-slider-wrapper', {
        'empty-communities': !numCommunities,
      })}>
      <div className="section-header">
        <div className="header-left">
          <GlobeIcon />
          <p className="section-title">Communities</p>
          <a href="/communities" className="link view-all">
            View all
          </a>
        </div>
        <p className="section-total caption">
          {`(Showing ${numCommunities} of ${communitiesData.paginator.total_entries})`}
        </p>
      </div>
      <div className="slider homepage-communities">
        <Slider
          {...sliderOptions(
            showPromotion ? numCommunities + 1 : numCommunities,
          )}>
          {communitiesData.rows.map((community) => (
            <div
              className="community-card-wrapper"
              key={`${community.data.id}`}>
              <Community
                community={
                  {
                    ...community.data,
                    link: community.link,
                  } as ICommunity
                }
              />
            </div>
          ))}
          {showPromotion && (
            <div
              className="community-card-wrapper"
              css={{
                maxWidth: 'inherit',
                flexGrow: 1,
              }}>
              <PromotionCard
                orgName={orgName}
                type={promotionType}
                promotionEmail={promotionEmail}
              />
            </div>
          )}
        </Slider>
      </div>
    </div>
  )
}
