import Button, { Variant } from 'components/Button'
import { useMentorshipExchangeDetailsContext } from './MentorshipExchangeDetailsContext'
import { useCallback, useEffect, useMemo, useState } from 'react'
import NotifyParticipantsModal from './Modals/NotifyParticipantsModal'

const MATCHES_REVIEW_TABLE = 'matches_review'
const MATCHES_ACCEPTED_TABLE = 'matches_accepted'
const PENDING_NOTIFICATION = 'pending_notification'

export default function MatchResultsPagePrimaryButtonComponent(): JSX.Element {
  const { exchange, counts, urls, possibleCohorts } =
    useMentorshipExchangeDetailsContext()

  const [cohortId, setCohortId] = useState<number>()
  useEffect(() => {
    setCohortId(exchange?.data?.group?.id ?? 0)
  }, [exchange])

  const notifyUrl = useMemo(() => {
    const url = urls.notify_participants
    const sep = url.indexOf('?') > -1 ? '&' : '?'
    return `${url}${sep}cohort_id=${cohortId}`
  }, [exchange, cohortId, urls])

  // Status detections
  const programClosed = useMemo(
    () => exchange.data.status == 'application_complete',
    [exchange],
  )
  const isMatchingLocked = useMemo(
    () =>
      exchange.data.notification_status === 'notified' ||
      exchange.data.notification_status === 'notifying',
    [exchange],
  )
  const hasPreviouslyNotified = useMemo(
    () => exchange.data.notification_status === 'reopened',
    [exchange],
  )

  // Modals
  const [openNotifyParticipantsModal, setOpenNotifyParticipantsModal] =
    useState(false)

  // Button text
  const primaryButtonText = useMemo(() => {
    if (isMatchingLocked) return 'Reopen matching'
    else if (hasPreviouslyNotified) return 'Notify pending participants'
    else return 'Notify participants'
  }, [exchange, programClosed, isMatchingLocked, hasPreviouslyNotified])

  // Button actions
  const reopenAction = useCallback(() => {
    window.location.href = urls.reopen_matching
  }, [exchange, programClosed, isMatchingLocked, hasPreviouslyNotified])
  const notifyAction = useCallback(() => {
    if (
      counts.pending_approval > 0 &&
      exchange.data.manager_approval_required
    ) {
      window.flash(
        'All pending manager responses must be approved or denied.',
        'alert',
      )
      return
    }

    // Unreviewed matches case
    if (counts[MATCHES_REVIEW_TABLE] > 0) {
      window.flash(
        'All matches under "Matches For Review" table must be accepted or rejected before notifying the participants.',
        'alert',
      )
      return
    }

    // 0 accepted matches case
    if (counts[MATCHES_ACCEPTED_TABLE] <= 0) {
      window.flash(
        "We're unable to notify participants as there are currently no accepted matches available.",
        'alert',
      )
      return
    }

    setOpenNotifyParticipantsModal(true)
  }, [counts, exchange, programClosed, isMatchingLocked, hasPreviouslyNotified])
  const notifyPendingAction = useCallback(() => {
    // 0 pending matches case
    if (counts[PENDING_NOTIFICATION] <= 0) {
      window.flash(
        "We're unable to notify participants as there are currently no pending matches available.",
        'alert',
      )
      return
    }

    // 0 accepted matches case
    if (counts[MATCHES_ACCEPTED_TABLE] <= 0) {
      window.flash(
        "We're unable to notify participants as there are currently no accepted matches available.",
        'alert',
      )
      return
    }

    setOpenNotifyParticipantsModal(true)
  }, [counts, exchange, programClosed, isMatchingLocked, hasPreviouslyNotified])

  return (
    <>
      <Button
        variant={Variant.PRIMARY}
        onClick={() => {
          if (isMatchingLocked) return reopenAction()
          else if (hasPreviouslyNotified) return notifyPendingAction()
          else notifyAction()
        }}>
        {primaryButtonText}
      </Button>
      <NotifyParticipantsModal
        isOpen={openNotifyParticipantsModal}
        readOnly={hasPreviouslyNotified}
        previouslyNotified={hasPreviouslyNotified}
        count={hasPreviouslyNotified ? counts[PENDING_NOTIFICATION] : 0}
        initialCohort={exchange?.data?.group?.id ?? 0}
        possibleCohorts={possibleCohorts}
        onUpdateCohort={(cohortId) => {
          setCohortId(cohortId)
        }}
        onSave={() => {
          window.location.href = hasPreviouslyNotified
            ? urls.notify_participants
            : notifyUrl
        }}
        onRequestClose={() => {
          setOpenNotifyParticipantsModal(false)
        }}
      />
    </>
  )
}
