import Input from 'components/Input'
import React from 'react'
import compact from 'lodash/compact'
import { TUserFilters } from 'state/Users'
import Container from 'components/Container'
import { Caption } from 'components/Typography'
import { css } from '@emotion/react'
import { boxShadow } from 'stylesheets/theme'
import { spacings } from 'stylesheets/theme'
import { colors } from 'stylesheets/theme'
import { isNullish } from 'components/helper-functions'
import Button, { ButtonComponentType, Variant } from 'components/Button'
import { SUpdateButton } from 'components/LeadrCumulativeReport/LeadrCumulativeReport.styles'
import { useContentDimensions } from 'hooks/useDimensions'

enum Breakpoints {
  NARROW = 1200,
  WIDE = 1201,
}

export type TFilterOptions = {
  locations: {
    city: string
    state: string
  }[]
  titles: string[]
  departments: string[]
  new_subscribers?: number
}

export const DEFAULT_FILTER_OPTIONS: TFilterOptions = {
  locations: [],
  titles: [],
  departments: [],
}

interface IMembershipFiltersProps {
  // When hideSearch is true, the search input will not be rendered
  hideSearch?: boolean
  // Use "city, state" for the value format of location.
  cityAndState?: boolean
  searchPlaceholder?: string
  filters: Partial<TUserFilters>
  filterOptions: TFilterOptions
  updateFilters: (newFilters: Partial<TUserFilters>) => void
  // Show all the filter options even if they are empty
  showAllFilters?: boolean
  className?: string
  // currentCount is the number of items currently displayed
  currentCount?: number
  // initialCount is the total number of items
  initialCount?: number
  // display 'showing 50 out fo 100' type of text
  displayCount?: boolean
  // display an action node at the end of filters to perform some action
  actionNode?: JSX.Element
  // has an update button
  hasUpdateButton?: boolean
  // text of the update button
  updateButtonText?: string
}

const filtersContainerStyle = css({
  boxShadow: boxShadow.container,
  borderRadius: spacings.radius,
  padding: spacings.grid_gap_basis_num * 2,
  backgroundColor: colors.backgrounds.white,
  marginBottom: spacings.grid_gap_basis_num * 3,
  flexGrow: 1,
  alignItems: 'flex-start',
  '.input-component-wrapper': {
    flex: '1 1',
    minWidth: 200,
    maxWidth: 350,
    width: '100%',
    marginRight: spacings.grid_gap_basis_num * 2,
    '&:last-child': {
      marginRight: 0,
    },
  },
  '.search': {
    '&::after': {
      top: spacings.grid_gap_basis_num * 4,
    },
    '.input-wrapper input': {
      lineHeight: '18px',
    },
  },
})

const filtersContainerNoMarginStyle = css({
  '.input-component-wrapper': {
    marginRight: 0,
  },
})

const MembershipFilters: React.FC<IMembershipFiltersProps> = ({
  hideSearch = false,
  cityAndState = false,
  searchPlaceholder = 'Search',
  filters,
  filterOptions,
  updateFilters,
  showAllFilters = false,
  className = '',
  currentCount,
  initialCount,
  displayCount,
  actionNode,
  hasUpdateButton = false,
  updateButtonText = 'Update Results',
}) => {
  const onSelectChange = (
    name: keyof TUserFilters,
    object: Record<string, boolean>,
  ) => {
    const key = Object.keys(object)[0]

    updateFilters({
      [name]: object[key],
    })
  }

  const closestBreakpoint = useContentDimensions(
    ...(Object.values(Breakpoints).filter(Number.isInteger) as number[]),
  )
  const isNarrow = closestBreakpoint === Breakpoints.NARROW

  return (
    <Container
      css={[
        filtersContainerStyle,
        isNarrow ? filtersContainerNoMarginStyle : null,
      ]}
      className={className}
      direction="column">
      <Container
        direction={`${isNarrow ? 'column' : 'row'}`}
        css={{
          width: '100%',
        }}>
        {!hideSearch && (
          <Input
            name="text"
            type="search"
            placeholder={searchPlaceholder}
            wrapperClassName="search"
            value={filters?.text}
            onChange={(data) => {
              const record = data as Record<string, string>
              updateFilters({
                text: record.text,
              })
            }}
            label="Search"
          />
        )}
        {(filterOptions.locations.length > 0 || showAllFilters) && (
          <Input
            type="select"
            isMulti
            name="filter-locations"
            label="Locations"
            onChange={(data) => {
              onSelectChange('city', data as Record<string, boolean>)
            }}
            options={filterOptions.locations.map((l) => ({
              label: compact([l.city, l.state]).join(', '),
              value: cityAndState ? `${l.city}, ${l.state}` : `${l.city}`,
            }))}
            value={filters?.city}
          />
        )}
        {(filterOptions.titles.length > 0 || showAllFilters) && (
          <Input
            type="select"
            isMulti
            name="filter-title"
            label="Title"
            onChange={(data) =>
              onSelectChange('title', data as Record<string, boolean>)
            }
            options={filterOptions.titles.map((t) => ({
              label: t,
              value: t,
            }))}
            value={filters?.title}
          />
        )}
        {(filterOptions.departments.length > 0 || showAllFilters) && (
          <Input
            type="select"
            isMulti
            name="filters-department"
            label="Department"
            onChange={(data) =>
              onSelectChange('department', data as Record<string, boolean>)
            }
            value={filters?.department}
            options={filterOptions.departments.map((d) => ({
              label: d,
              value: d,
            }))}
          />
        )}
        {hasUpdateButton ? (
          <Button
            css={SUpdateButton}
            as={ButtonComponentType.BUTTON}
            variant={Variant.PRIMARY}
            onClick={() => {
              updateFilters(filters)
            }}>
            {updateButtonText}
          </Button>
        ) : (
          actionNode && actionNode
        )}
      </Container>
      {displayCount && !isNullish(currentCount) && !isNullish(initialCount) && (
        <Caption>
          Showing {currentCount} out of {initialCount}
        </Caption>
      )}
    </Container>
  )
}

export default MembershipFilters
