import Container from 'components/Container/Container'
import Modal, { IModalProps } from 'components/Modal'
import { Caption, Paragraph } from 'components/Typography'
import { IFilterItem } from './types'
import { colors, spacings } from 'stylesheets/theme'
import { AudienceContext, IAudienceCountsByType } from './AudienceContext'
import { css } from '@emotion/react'
import InformationIcon from '../../images/icons/info.svg'
import AdvanceFilterResults from './AdvanceFilterResults'
import { useContext, useMemo } from 'react'
import Switch from 'components/Switch'

const helperTextStyle = css({
  background: colors.backgrounds.page,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: colors.borders.gray,
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 8,
  paddingBottom: 8,
  verticalAlign: 'middle',
  display: 'flex',
  flexDirection: 'row',
  gap: spacings.grid_gap_basis_num * 0.5,
  alignItems: 'center',
  width: '100%',
  svg: {
    path: {
      fill: colors.text.text_5,
      stroke: colors.text.text_5,
    },
  },
})

interface IAudienceConfirmationModalProps
  extends Omit<IModalProps, 'children'> {
  saveAndConfirm?: boolean
  isCsvImport?: boolean
  csvImportFile: string | null
  audienceCount: IAudienceCountsByType
  filterGroups?: IFilterItem[][]
  resultingFilterGroups?: IFilterItem[][]
  isAdditionalAudience?: boolean
}

export default function AudienceConfirmationModal({
  saveAndConfirm = false,
  isCsvImport = false,
  audienceCount,
  resultingFilterGroups = [],
  isAdditionalAudience = false,
  ...props
}: IAudienceConfirmationModalProps): JSX.Element {
  const { isAdditionalUsers, object, setAdditionalAudienceMandatory } =
    useContext(AudienceContext)

  const shortly =
    'The users will receive the invitation shortly after confirmation. This action cannot be undone.'

  const topMsg = useMemo(() => {
    const cnt = isCsvImport
      ? audienceCount.csvFile.audienceCount.matched
      : audienceCount.filters.audienceCount.matched
    const ending = isAdditionalUsers
      ? shortly
      : `No invitations will be sent out until the ${
          object == 'event' ? 'event' : 'call to action'
        } is published. You can continue editing this after confirming.`
    if (isCsvImport) {
      return `Your invitations will go to the ${cnt} users using the uploaded CSV files. ${ending}`
    } else {
      return `Your invitation will go to the ${cnt} users who match these specified filters. ${ending}`
    }
  }, [audienceCount, isCsvImport])

  const hasFilter = useMemo(
    () =>
      audienceCount.filters?.audienceCount?.matched > 0 ||
      audienceCount.filters?.audienceCount?.not_matched > 0,
    [audienceCount],
  )

  const hasCsvImport = useMemo(
    () =>
      audienceCount.csvFile?.audienceCount?.matched > 0 ||
      audienceCount.csvFile?.audienceCount?.not_matched > 0,
    [audienceCount],
  )

  const overrideWarning = useMemo(() => {
    if (isCsvImport && hasFilter) {
      return 'Any changes made to the advanced filter will be overwritten and updated with this CSV file.'
    } else if (!isCsvImport && hasCsvImport) {
      return 'This advanced filter will overwrite any selected CSV file, if applicable.'
    } else {
      return ''
    }
  }, [hasFilter, hasCsvImport, isCsvImport])

  return (
    <Modal
      {...props}
      largeTitle
      title="Please confirm the selected recipients"
      submitButton={
        saveAndConfirm && !isAdditionalUsers ? 'save and confirm' : 'confirm'
      }
      cancelButton={
        saveAndConfirm && !isAdditionalUsers ? "don't save" : 'back to editing'
      }>
      <Container
        css={{
          flexDirection: 'column',
          gap: 26,
          alignItems: 'flex-start',
        }}>
        <Paragraph>{topMsg}</Paragraph>
        <Container
          css={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginLeft: spacings.grid_gap_basis_num * 2,
            gap: 0,
          }}>
          {isCsvImport ? null : (
            <AdvanceFilterResults
              resultingFilterGroups={resultingFilterGroups}
            />
          )}
        </Container>
        {isAdditionalAudience && object == 'event' && (
          <Container
            css={{
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}>
            <Paragraph>
              Should the notified users be registered automatically?
            </Paragraph>
            <Switch
              css={{ label: { whiteSpace: 'nowrap' } }}
              onChange={(inputData) =>
                setAdditionalAudienceMandatory(inputData.target?.checked)
              }
              key="audience-mandatory"
              id={`${object}-mandatory`}
              name={`${object}[mandatory]`}
              controlName="audience-mandatory"
            />
          </Container>
        )}
        {overrideWarning && (
          <Caption css={helperTextStyle}>
            <InformationIcon />
            {overrideWarning}
          </Caption>
        )}
      </Container>
    </Modal>
  )
}
