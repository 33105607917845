import Container from 'components/Container'
import { Paragraph } from 'components/Typography'
import Select, { IOption } from 'components/Inputs/Select'
import { spacings } from 'stylesheets/theme'
import Button, { Variant } from 'components/Button'
import {
  FilterOption,
  IAllFilterOptions,
  defaultAllFilterOptions,
} from './types'
import TrashIcon from 'images/icons/trash.svg'
import { useMemo } from 'react'
import NameFilterItemInput from './NameFilterItemInput'
import FilterItemInput from './FilterItemInput'

type TFilterOption = IOption & {
  filter: string
}

export interface IFilterItemProps {
  groupIndex: number
  itemIndex: number
  groupFilterItemsCount: number
  filterType?: FilterOption | null
  filterInputs?: any[]
  removeFilterItem?: (itemIndex: number) => void
  onFilterItemChange?: (filterType: FilterOption, filterInputs: any) => void
  subscribersUrl?: string
  filtersUrl?: string
  availableOptions: string[]
  allOptions?: IAllFilterOptions
}

const dropdownStyle = {
  control: {
    width: '195px',
  },
  menu: {
    width: '195px',
  },
}

interface IEndIconListProps {
  itemIndex: number
  itemsCount: number
  trashButton: JSX.Element
}

function EndIconList({
  itemIndex,
  itemsCount,
  trashButton,
}: IEndIconListProps): JSX.Element {
  if (itemIndex !== itemsCount - 1) {
    return (
      <Container
        css={{ minWidth: 60, justifyContent: 'space-between', flex: 1 }}>
        <Paragraph>AND</Paragraph>
        {trashButton}
      </Container>
    )
  } else {
    return (
      <Container css={{ minWidth: 60, justifyContent: 'flex-end', flex: 1 }}>
        {trashButton}
      </Container>
    )
  }
}

export default function FilterItem({
  groupIndex,
  itemIndex,
  groupFilterItemsCount,
  removeFilterItem = () => {},
  onFilterItemChange = () => {},
  filterType,
  filterInputs = [],
  subscribersUrl,
  filtersUrl,
  availableOptions,
  allOptions = defaultAllFilterOptions,
}: IFilterItemProps): JSX.Element {
  const filterItemTypeName = useMemo(
    () => `filter-group-${groupIndex}-item-${itemIndex}-type`,
    [groupIndex, itemIndex],
  )
  const filterItemInputsName = useMemo(
    () => `filter-group-${groupIndex}-item-${itemIndex}-inputs`,
    [groupIndex, itemIndex],
  )

  const type = useMemo(() => {
    switch (filterType) {
      case 'Title':
        return 'titles'
      case 'Department':
        return 'departments'
      case 'Location':
        return 'locations'
      default:
        return ''
    }
  }, [filterType])

  return (
    <Container
      css={{
        gap: 15,
        '& .input-component-wrapper': {
          margin: 0,
        },
        marginBottom: spacings.grid_gap_basis,
        width: '100%',
      }}>
      <Select<TFilterOption>
        id={filterItemTypeName}
        name={filterItemTypeName}
        placeholder="Select"
        options={availableOptions.map((filter_option) => ({
          label: filter_option ? filter_option : '- Select -',
          value: filter_option,
          filter: filter_option,
        }))}
        styleOverrides={dropdownStyle}
        value={filterType || ''}
        onChange={(value: Record<string, FilterOption>) => {
          // Reset the value for the field, because values for each type are
          // not valid for each other type. For instance, valid values for
          // "Location" make no sense when applied to "Name".
          onFilterItemChange(value[filterItemTypeName], [])
        }}
      />
      <Paragraph>Contains</Paragraph>
      {filterType === 'Name' ? (
        <NameFilterItemInput
          name={filterItemInputsName}
          filterInputs={filterInputs}
          onFilterItemChange={(changedInputs) => {
            onFilterItemChange(filterType, changedInputs)
          }}
          subscribersUrl={subscribersUrl}
        />
      ) : (
        <FilterItemInput
          name={filterItemInputsName}
          filterInputs={filterInputs}
          type={type}
          onFilterItemChange={(changedInputs) => {
            onFilterItemChange(filterType, changedInputs)
          }}
          filtersUrl={filtersUrl}
          allOptions={allOptions}
        />
      )}
      <EndIconList
        itemIndex={itemIndex}
        itemsCount={groupFilterItemsCount}
        trashButton={
          <Button
            variant={Variant.UNSTYLED}
            onClick={() => removeFilterItem(itemIndex)}
            type="Button">
            <TrashIcon
              css={{
                width: 20,
                height: 20,
              }}
            />
          </Button>
        }
      />
    </Container>
  )
}
