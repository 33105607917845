import Modal, { Size } from 'components/Modal'
import { BADGE_DATA } from './LeadrShareOnLinkedInModal.constants'
import { ILeadrShareOnLinkedInModalProps } from './LeadrShareOnLinkedInModal.types'
import { translateAllEnLiterals } from 'utils/translateEnLiterals'
import { Paragraph } from 'components/Typography'
import {
  SHeading,
  SModal,
  SMobileModal,
  SModalContent,
  SParagraph,
  SShareOnLinkedInIcon,
  SCloseButtonMobile,
} from './LeadrShareOnLinkedInModal.styles'
import pngLinkedInIcon from 'images/icons/linkedin_14.png'
import { useMemo } from 'react'
import Button, { ButtonComponentType, Variant } from 'components/Button'
import useMediaQuery from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'

export default function LeadrShareOnLinkedInModal({
  strings,
  archetype,
  ...props
}: ILeadrShareOnLinkedInModalProps): JSX.Element {
  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)

  const linkedInIcon14 = useMemo(() => {
    return (
      <img
        aria-label={strings.button}
        src={pngLinkedInIcon}
        css={SShareOnLinkedInIcon}></img>
    )
  }, [])

  return (
    <Modal
      largeTitle
      title={strings.title}
      size={Size.large}
      {...props}
      css={[SModal, isMobile && SMobileModal]}>
      <div css={SModalContent}>
        {BADGE_DATA[archetype.leadr].badge}

        <h2 css={SHeading}>
          {translateAllEnLiterals(strings.heading, {
            archetype: archetype.name,
          })}
        </h2>

        <Paragraph css={SParagraph}>
          {translateAllEnLiterals(strings.description, {
            archetype: archetype.name,
          })}
        </Paragraph>

        <Button
          as={ButtonComponentType.LINK}
          variant={Variant.SECONDARY}
          target="_blank"
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            BADGE_DATA[archetype.leadr].link,
          )}`}>
          {linkedInIcon14}
          {strings.button}
        </Button>

        {isMobile && (
          <Button
            css={SCloseButtonMobile}
            as={ButtonComponentType.LINK}
            variant={Variant.LINK}
            onClick={() => {
              props.onRequestClose()
            }}>
            Cancel
          </Button>
        )}
      </div>
    </Modal>
  )
}
