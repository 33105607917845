import Modal, { Size, IModalProps } from 'components/Modal'

export default function LeadrAlreadySubmittedModal({
  ...modalProps
}: Omit<
  IModalProps,
  'largeTitle' | 'title' | 'children' | 'onSave' | 'size' | 'cancelButton'
>): JSX.Element {
  return (
    <Modal
      largeTitle
      title="You’ve already submitted a LEADR™ assessment"
      cancelButton="close"
      size={Size.large}
      onSave={() => {}}
      {...modalProps}>
      <p>
        Please review your LEADR™ assessment results to understand the strengths
        identified. Share your LEADR™ badge on LinkedIn to showcase your unique
        strengths and qualities.
      </p>
    </Modal>
  )
}
