import AudienceContextProvider, { IAudiencesUrls } from './AudienceContext'
import AudienceComponent from './AudienceComponent'
import { IModalProps } from 'components/Modal'
import { Audience, TEventCallType } from 'types'
import { ICsvHistoryTable } from './types'

interface IAudienceModalProps {
  title?: string
  subtitle?: string
  object: TEventCallType
  objectId: number
  initialAudience?: Audience
  importHistory?: ICsvHistoryTable
  isOpen?: IModalProps['isOpen']
  onClose?: (hasFilterOrCsv: boolean) => void
  onSave?: (hasFilterOrCsv: boolean, audienceUserCount: number) => void
  urls: IAudiencesUrls
  isAdditionalUsers?: boolean
  cohostIds?: number[]
}

export default function AudienceModal({
  title,
  subtitle,
  object,
  objectId,
  initialAudience,
  importHistory,
  isOpen = false,
  onClose = () => {},
  onSave = () => {},
  urls = {},
  isAdditionalUsers = false,
  cohostIds,
}: IAudienceModalProps): JSX.Element {
  return (
    <AudienceContextProvider
      isOpen={isOpen}
      object={object}
      objectId={objectId}
      initialAudience={initialAudience}
      importHistory={importHistory}
      urls={urls}
      isAdditionalUsers={isAdditionalUsers}
      cohostIds={cohostIds}>
      <AudienceComponent
        title={title}
        subtitle={subtitle}
        isOpen={isOpen}
        object={object}
        onClose={onClose}
        onSave={onSave}
        urls={urls}
      />
    </AudienceContextProvider>
  )
}
