import { css } from '@emotion/react'
import Container from 'components/Container'
import SectionHeader from 'components/SectionHeader/SectionHeader'
import { colors, spacings } from 'stylesheets/theme'

const subtitleStyle = css({
  color: colors.teal_dark,
})

const cohortSubtitleStyle = css({
  color: colors.purple,
})

interface ICommunityContentProps {
  isCohort?: boolean
  className?: string
  subtitle: string
  missionStatementHtml: string
}

export default function CommunityContent({
  isCohort = false,
  className = '',
  subtitle,
  missionStatementHtml,
}: ICommunityContentProps): JSX.Element {
  console.log('in CommunityContent')
  return (
    <Container
      direction="column"
      css={{ alignItems: 'flex-start', gap: spacings.grid_gap_basis_num / 2 }}
      className={className}>
      <SectionHeader>description</SectionHeader>
      <h2 css={[subtitleStyle, isCohort && cohortSubtitleStyle]}>{subtitle}</h2>
      <div
        className="mindr-rich-content"
        dangerouslySetInnerHTML={{ __html: missionStatementHtml }}></div>
    </Container>
  )
}
