import Container from 'components/Container'
import Modal, { IModalProps, Size } from 'components/Modal'
import { Paragraph } from 'components/Typography'
import { colors, spacings } from 'stylesheets/theme'
import Switch from 'components/Switch'
import { useCallback, useContext, useState } from 'react'
import { css } from '@emotion/react'
import CsvImportComponent from './CsvImportComponent'
import {
  AudienceContext,
  IAudiencesUrls,
  initialFilterGroups,
} from './AudienceContext'
import AdvancedFilterComponent from './AdvancedFilterComponent'
import AudienceConfirmationModal from './AudienceConfirmationModal'
import { TEventCallType } from 'types'
import _ from 'lodash'
import { DEFAULT_CSV_MESSAGING } from './helper'

const containerStyle = css({
  marginTop: spacings.grid_gap_basis_num * 3,
  backgroundColor: colors.backgrounds.page,
  width: '100%',
  minHeight: 360,
  padding: spacings.grid_gap_basis_num * 2,
  display: 'flex',
  gap: spacings.grid_gap_basis_num * 2,
  flexDirection: 'column',
  overflow: 'hidden',
})

interface IAudienceComponentProps {
  title?: string
  subtitle?: string
  object: TEventCallType
  isOpen?: IModalProps['isOpen']
  onClose?: (hasFilterOrCsv: boolean) => void
  onSave?: (hasFilterOrCsv: boolean, audienceUserCount: number) => void
  urls: IAudiencesUrls
}

export default function AudienceComponent({
  title,
  subtitle,
  object = 'event',
  isOpen = false,
  onClose = () => {},
  onSave = () => {},
  urls = {},
}: IAudienceComponentProps): JSX.Element {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const {
    filterGroups,
    resultingFilterGroups,
    isCsvImport,
    csvImportFile,
    audienceImportTable,
    setCsvImport,
    addNewFilterItem,
    removeFilterItem,
    addFilterGroup,
    removeFilterGroup,
    onFilterItemChange,
    audienceCount,
    onSaveAudience,
    resetFilterGroups,
    resetCsvImport,
    updateEstimatedAudienceCount,
    isAdditionalUsers,
    shouldConfirmClose,
    hasSavedAudience,
  } = useContext(AudienceContext)

  const [showZeroAudienceError, setShowZeroAudienceError] =
    useState<boolean>(false)
  const hasPositiveAudienceCount = useCallback(() => {
    if (isCsvImport) {
      return audienceCount.csvFile.audienceCount.matched > 0
    } else {
      return audienceCount.filters.audienceCount.matched > 0
    }
  }, [audienceCount, isCsvImport, filterGroups, csvImportFile])

  const [saveAndConfirm, setSaveAndConfirm] = useState<boolean>(false)

  const onConfirmationSave = useCallback(() => {
    const currentSavedValues = onSaveAudience()
    setOpenConfirmationModal(false)
    onSave(
      hasSavedAudience(currentSavedValues),
      isCsvImport
        ? audienceCount.csvFile.audienceCount.matched
        : audienceCount.filters.audienceCount.matched,
    )
  }, [onSaveAudience, onSave, onClose, audienceCount, hasSavedAudience])

  const onConfirmationRequestClose = useCallback(
    (fromModalX = false) => {
      if (saveAndConfirm && !isAdditionalUsers) {
        if (fromModalX) {
          setOpenConfirmationModal(false)
          return
        }
        const hasSavedValues = hasSavedAudience()
        onClose(hasSavedValues)
        resetFilterGroups()
        setOpenConfirmationModal(false)
      } else {
        setOpenConfirmationModal(false)
      }
    },
    [
      saveAndConfirm,
      onClose,
      resetCsvImport,
      resetFilterGroups,
      hasSavedAudience,
    ],
  )

  return (
    <Modal
      isSubmitDisabled={
        (!isCsvImport && _.isEqual(filterGroups, initialFilterGroups)) ||
        (isCsvImport && audienceCount.csvFile.audienceCount.matched <= 0)
      }
      isOpen={isOpen}
      largeTitle
      title={title || 'Who should we notify?'}
      size={Size.wide}
      css={{
        '&.wide': {
          maxWidth: 1172,
        },
      }}
      onRequestClose={() => {
        setSaveAndConfirm(false)
        const shouldConfirm = shouldConfirmClose()
        if (
          !isAdditionalUsers &&
          hasPositiveAudienceCount() &&
          shouldConfirm.value
        ) {
          setSaveAndConfirm(true)
          setOpenConfirmationModal(true)
          return
        }
        const savedAudience = hasSavedAudience()
        onClose(savedAudience)
        resetCsvImport()
        resetFilterGroups()
      }}
      onSave={() => {
        if (!hasPositiveAudienceCount()) {
          setShowZeroAudienceError(true)
          return
        }
        updateEstimatedAudienceCount()
        setOpenConfirmationModal(true)
      }}
      submitButton={isAdditionalUsers ? 'Send' : 'Save'}
      cancelButton="Close">
      <Paragraph>{subtitle || DEFAULT_CSV_MESSAGING}</Paragraph>
      <form id="audience-form">
        <div css={containerStyle}>
          <Container css={{ justifyContent: 'space-between' }}>
            <h4
              css={{
                color: colors.text.text_5,
              }}>
              {isCsvImport ? 'CSV Import' : 'Advanced filter'}
            </h4>
            <Switch
              controlName={`${object}[audience_filter]`}
              value="true"
              checked={isCsvImport}
              checkedText="CSV import"
              uncheckedText="Advanced filter"
              onChange={() => {
                setCsvImport(!isCsvImport)
              }}
            />
          </Container>
          {isCsvImport ? (
            <CsvImportComponent
              tableData={audienceImportTable.tableData}
              tableMeta={audienceImportTable.tableMeta}
              exampleCsvUrl={audienceImportTable.exampleCsvUrl}
            />
          ) : (
            <AdvancedFilterComponent
              filterGroups={filterGroups}
              addNewFilterItem={addNewFilterItem}
              removeFilterItem={removeFilterItem}
              addFilterGroup={addFilterGroup}
              removeFilterGroup={removeFilterGroup}
              audienceCount={audienceCount}
              onFilterItemChange={onFilterItemChange}
              subscribersUrl={urls.subscribersUrl}
              filtersUrl={urls.filtersUrl}
            />
          )}
        </div>
      </form>
      <AudienceConfirmationModal
        saveAndConfirm={saveAndConfirm}
        isOpen={openConfirmationModal}
        csvImportFile={csvImportFile}
        onRequestClose={onConfirmationRequestClose}
        onSave={onConfirmationSave}
        audienceCount={audienceCount}
        size={Size.large}
        filterGroups={filterGroups}
        isCsvImport={isCsvImport}
        resultingFilterGroups={resultingFilterGroups}
        isAdditionalAudience={isAdditionalUsers}
      />
      <Modal
        largeTitle
        isOpen={showZeroAudienceError}
        title={
          isCsvImport
            ? 'No users have been uploaded'
            : 'No users have been selected'
        }
        submitButton="Ok"
        size={Size.small}
        onRequestClose={() => setShowZeroAudienceError(false)}
        onSave={() => setShowZeroAudienceError(false)}>
        <Paragraph>
          {isCsvImport
            ? 'Please upload a file containing valid user data.'
            : 'Please adjust the filters to display more selected user results.'}
        </Paragraph>
      </Modal>
    </Modal>
  )
}
