import { css } from '@emotion/react'
import DefaultAvatar from './DefaultAvatar'

interface AvatarProps {
  profilePath?: string
  userInitial?: string
  avatarUrl?: string
  size?: number
}

const avatarStyle = css({
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  objectFit: 'cover',
})

export default function Avatar({
  profilePath = null,
  userInitial = null,
  avatarUrl = null,
  size = 30,
}: AvatarProps): JSX.Element {
  return (
    <a
      aria-label="avatar"
      href={profilePath}
      css={{
        textDecoration: 'none',
      }}>
      {avatarUrl ? (
        <img
          aria-label="user avatar image"
          css={[
            avatarStyle,
            css({
              height: size,
              width: size,
            }),
          ]}
          src={
            avatarUrl.startsWith('http') ? avatarUrl : `/${avatarUrl}`
          }></img>
      ) : (
        <DefaultAvatar userInitial={userInitial} size={size} />
      )}
    </a>
  )
}
