import axios from 'axios'
import _ from 'lodash'

import Input from 'components/Input'
import { useCallback, useContext, useEffect, useState } from 'react'
import { IUser } from 'types'
import { AudienceContext } from './AudienceContext'

interface INameFilterItemInputProps {
  name: string
  type?: string
  filterInputs?: any[]
  subscribersUrl?: string
  onFilterItemChange?: (filterInputs: any) => void
  className?: string
}

function unrollUserResults(data) {
  const rows = []

  if (data.rows && data.rows.length) {
    data.rows.forEach((row) => {
      if (row.data && row.data.id) {
        rows.push(row.data)
      }
    })
  }

  return rows
}

export default function NameFilterItemInput({
  name,
  filterInputs,
  onFilterItemChange,
  subscribersUrl,
  ...props
}: INameFilterItemInputProps): JSX.Element {
  const { isOpen } = useContext(AudienceContext)

  const [initialUsers, setInitialUsers] = useState<IUser[]>([])
  const [selectedIds, setSelectedIds] = useState([])

  const loadUsersOptions = useCallback(
    _.debounce((inputValue, callback) => {
      axios
        .get(`${subscribersUrl}.json`, {
          params: {
            'filter[*]': inputValue,
            per_page: !!inputValue ? undefined : 8,
            active_only: true,
            exclude: selectedIds,
          },
        })
        .then(({ data: { data } }) => {
          isOpen && callback(unrollUserResults(data))
        })
    }, 400),
    [subscribersUrl, selectedIds],
  )

  useEffect(() => {
    loadUsersOptions('', setInitialUsers)
  }, [selectedIds])

  const handleOnChange = useCallback(
    (inputData: Record<string, unknown>) => {
      try {
        const selected = inputData[name] as IUser[]
        if (selected && Array.isArray(selected)) {
          onFilterItemChange(selected)
          setSelectedIds(selected.map((u) => u.id))
        }
      } catch (e) {
        console.log(
          'AJAX ERROR: User lookup failed. Unknown user:',
          e,
          inputData,
        )
      }
    },
    [name, onFilterItemChange],
  )

  return (
    <Input
      css={{
        width: 537,
      }}
      className={props.className}
      name={name}
      type="async_select"
      isMulti
      selectValue={filterInputs}
      getOptionLabel={(option) => `${option.full_name}`}
      getOptionValue={(option) => option.id}
      loadOptions={loadUsersOptions}
      defaultOptions={initialUsers}
      onChange={handleOnChange}
      isClearable={false}
      placeholder={undefined}
      getOptionKey={(o) => o.id}
      {...props}
    />
  )
}
