import React, { useCallback, useMemo } from 'react'
import cx from 'classnames'

import Input from 'components/Input'

import DeleteIcon from '../../../images/icons/delete.svg'
import Container from 'components/Container/Container'

type TImageWithTitleErrors = {
  image?: string[]
  title?: string[]
}

export type TImageWithTitleValue = {
  id: string | undefined
  url: string | undefined
  fileName: string | undefined
  title: string | undefined
  errors: TImageWithTitleErrors
  isMarkedForDestruction: boolean
  hidden?: boolean
  imageData?: any
}

interface IImageWithTitleRow {
  name: string
  value: TImageWithTitleValue
  textHeader: string
  onChange: (newValue: TImageWithTitleValue) => void
  className?: string
}

const ImageWithTitleRow: React.FC<IImageWithTitleRow> = ({
  name,
  value,
  textHeader,
  onChange,
  className = '',
}) => {
  const onDelete = useCallback(
    (e) => {
      e.preventDefault()
      onChange({
        ...value,
        isMarkedForDestruction: true,
        hidden: true,
      })
    },
    [value, onChange],
  )

  const id = name.replace(/\]\[|\[|\]/g, '_')

  const imageErrors = useMemo(() => {
    const errors = value?.errors?.image
    if (errors && typeof errors === 'string') {
      return [value?.errors?.image]
    }
    if (errors && Array.isArray(errors)) {
      return value?.errors?.image
    }
    return []
  }, [value])

  if (value?.isMarkedForDestruction) {
    return (
      <>
        <input
          type="hidden"
          value={value.id || undefined}
          name={`${name}[id]`}
          onChange={(newValue) =>
            onChange({ ...value, id: newValue[`${name}[id]`] as string })
          }
        />
        <input type="hidden" value="true" name={`${name}[_destroy]`} />
      </>
    )
  }

  return (
    <Container
      css={[
        {
          '& .input-component-wrapper': {
            marginRight: 0,
            marginBottom: 0,
          },
        },
      ]}
      className={cx('row dragable', className)}>
      <Input
        type="image"
        wrapperClassName="files-component-wrapper"
        label={`Image*`}
        imageType="square"
        textPreview
        textHeader={textHeader}
        selectFileLabel="Select Image"
        name={`${name}[image]`}
        id={`${id}image`}
        value={value?.fileName}
        deleteInputName={`${name}[remove_image]`}
        error={imageErrors.join(',')}
        imageData={value?.imageData}
        onChange={(inputData) => {
          onChange({
            ...inputData,
          } as TImageWithTitleValue)
        }}
        css={{
          minWidth: 200,
        }}
      />
      <Input
        type="text"
        label="Image Caption"
        name={`${name}[title]`}
        id={`${id}title`}
        maxLength="45"
        error={value.errors.title?.join(',')}
        onChange={(newValue) =>
          onChange({ ...value, title: newValue[`${name}[title]`] as string })
        }
        value={value.title || undefined}
        css={{
          minWidth: 480,
        }}
      />
      <input
        type="hidden"
        value={value.id || undefined}
        name={`${name}[id]`}
        id={`${id}id`}
        onChange={(newValue) =>
          onChange({ ...value, id: newValue[`${name}[id]`] as string })
        }
      />
      <button
        aria-label="remove image and title"
        className="not-styled-button delete"
        type="button"
        onClick={onDelete}>
        <DeleteIcon width="24px" height="24px" />
      </button>
    </Container>
  )
}

export default ImageWithTitleRow
