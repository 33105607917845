import DragAndDrop from 'components/DragAndDrop'
import { Paragraph } from 'components/Typography'
import { colors, spacings } from 'stylesheets/theme'
import { AnswerWithRank } from './types'

interface AssessmentQuestionProps {
  question: string
  answers: AnswerWithRank[]
  // These are the answers that have not been ranked, which are needed for mobile to display the options
  defaultAnswers: AnswerWithRank[]
  setAnswers: (answers: AnswerWithRank[]) => void
  defaultIsMobile?: boolean
}

export default function AssessmentQuestion({
  question,
  answers,
  defaultAnswers,
  setAnswers,
  defaultIsMobile = false,
}: AssessmentQuestionProps): JSX.Element {
  return (
    <div>
      <h2
        css={{
          marginBottom: spacings.grid_gap_basis_num * 3,
        }}>
        {question}
      </h2>
      <DragAndDrop
        items={answers}
        setItems={setAnswers}
        defaultItems={defaultAnswers}
        css={{
          '& .draggable-container': {
            backgroundColor: colors.backgrounds.white,
            border: `1px solid ${colors.borders.gray}`,
            borderRadius: spacings.draggable_radius,
            width: 'fit-content',
            height: 100,
          },
        }}
        component={(index, item: AnswerWithRank) => (
          <Paragraph bold>{item.answer}</Paragraph>
        )}
        defaultIsMobile={defaultIsMobile}
        numbered></DragAndDrop>
    </div>
  )
}
