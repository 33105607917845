import Button, { ButtonComponentType, Variant } from 'components/Button'
import Container from 'components/Container'
import DotMenu from 'components/DotMenu/DotMenu'
import MembershipFilters from 'components/MembershipFilters'
import Table, { ITableProps } from 'components/Table'
import { PeopleWidget } from 'components/Widget'
import InquiriesWidget from 'components/Widget/InquiriesWidget'
import { useCallback, useMemo, useState } from 'react'
import useUsersState from 'state/Users'
import { colors, spacings } from 'stylesheets/theme'
import EyeIcon from 'images/icons/eye.svg'
import LeadrAssessmentInviteModal from './LeadrAssessmentInviteModal'
import axios from 'axios'
import ActivityLogModal from 'components/MentorshipExchangeDetails/Modals/ActivityLogModal'
import Switch from 'components/Switch'
import _ from 'lodash'
import { css } from '@emotion/react'
import useMediaQuery from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'

export type TFilterOptions = {
  locations: {
    city: string
    state: string
  }[]
  titles: string[]
  departments: string[]
}

interface ILeadrIndexOverviewProps
  extends Omit<ITableProps, 'selectedFilters'> {
  counters: {
    participants: number
    retake_inquiries: number
  }
  tableFilters: ITableProps['selectedFilters']
  filters: TFilterOptions
  cumulativeReportUrl: string
  assessmentUrl: string
  uploadInviteCsvUrl: string
  // table data that contains no import history data, this is used to display an empty import history table in modal
  emptyImportHistoryTableData: ITableProps
  inviteUrl: string
  usersNotSubmittedAssessmentCount: number
  activityLogsTableProps: {
    data: ITableProps['tableData']
    meta: ITableProps['tableMeta']
  }
  exampleUrl: string
}

const narrowWidgetsStyle = css({
  gap: spacings.widget_gap,
  marginBottom: spacings.grid_gap_basis_num * 3,
  width: '100%',
  '> div.widget': {
    flexGrow: 1,
    maxWidth: `100%`,
    width: '100%',
  },
})
const fullWidgetsStyle = css({
  gap: spacings.widget_gap,
  marginBottom: spacings.grid_gap_basis_num * 3,
  width: '100%',
  '> div.widget': {
    flexGrow: 1,
    maxWidth: `calc((100% - ${spacings.widget_gap * 2}px) / 3)`,
  },
})

export default function LeadrIndexOverview({
  tableData: initialData,
  tableMeta: initialMeta,
  counters: initialCounters,
  filters,
  cumulativeReportUrl,
  assessmentUrl,
  uploadInviteCsvUrl,
  emptyImportHistoryTableData,
  inviteUrl,
  usersNotSubmittedAssessmentCount,
  activityLogsTableProps: {
    data: initialActivityLogsTableData,
    meta: initialActivityLogsTableMeta,
  },
  exampleUrl,
}: ILeadrIndexOverviewProps): JSX.Element {
  const {
    data,
    meta,
    counters,
    filters: selectedFilters,
    tableFilters,
    loadPage,
    updateOrder,
    updateFilters,
  } = useUsersState({
    url: initialMeta.url,
    initialData,
    initialMeta,
    initialFilters: {},
    initialCounters,
  })

  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)

  const isNarrow = isMobile //closestBreakpoint === Breakpoints.NARROW

  const [activityLogsTableData, setActivityLogsTableData] = useState(
    initialActivityLogsTableData,
  )
  const [activityLogsTableMeta, setActivityLogsTableMeta] = useState(
    initialActivityLogsTableMeta,
  )
  const [openInviteModal, setOpenInviteModal] = useState(false)
  const [openActivityLogModal, setOpenActivityLogModal] = useState(false)
  const [importId, setImportId] = useState(null)
  const [invitesCount, setInvitesCount] = useState(
    usersNotSubmittedAssessmentCount,
  )
  const [destroyUploadedImportFileUrl, setDestroyUploadedImportFileUrl] =
    useState(null)
  const [importHistoryTableData, setImportHistoryTableData] = useState(
    emptyImportHistoryTableData,
  )
  const [csvImportErrorMessage, setCsvImportErrorMessage] = useState('')

  /**
   * Upload a CSV file to import users to invite
   * Returns the import history table data, and the destroy import url
   */
  const uploadCsvImportFile = useCallback(
    async (csvFile) => {
      axios
        .post(uploadInviteCsvUrl, {
          authenticity_token: window.authenticity_token,
          csv_import_file: csvFile,
        })
        .then((response) => {
          if (response.data) {
            const { destroy_import_url, import_history, import_id, message } =
              response.data
            if (message) {
              setCsvImportErrorMessage(message)
              return
            }
            setImportHistoryTableData(import_history)
            if (import_history.tableData?.rows[0]?.data?.added) {
              setInvitesCount(import_history.tableData.rows[0].data.added)
            } else {
              setInvitesCount(0) // This should never happen, but if it does, 0 is the number
            }
            setDestroyUploadedImportFileUrl(destroy_import_url)
            setImportId(import_id)
          }
        })
    },
    [uploadInviteCsvUrl, setImportHistoryTableData],
  )

  /**
   * Destroy the uploaded import file and invitees that were created from the file
   */
  const destroyUploadedImportFile = useCallback(
    async (onAllow?: () => void) => {
      if (destroyUploadedImportFileUrl) {
        if (onAllow) {
          onAllow()
        }
        axios
          .delete(destroyUploadedImportFileUrl, {
            params: { authenticity_token: window.authenticity_token },
          })
          .then(() => {
            setImportHistoryTableData(emptyImportHistoryTableData)
            setInvitesCount(usersNotSubmittedAssessmentCount)
            setDestroyUploadedImportFileUrl(null)
            setImportId(null)
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        // There might have been an error trying to upload csv
        setCsvImportErrorMessage('')
        if (onAllow) {
          onAllow()
        }
      }
    },
    [destroyUploadedImportFileUrl, setImportHistoryTableData],
  )

  /**
   * Close the invite modal by closing it explicitly, and destroying the file that was uploaded
   */
  const onRequestCloseInviteModal = useCallback(async () => {
    await destroyUploadedImportFile()
    setOpenInviteModal(false)
  }, [destroyUploadedImportFile])

  /**
   * This is being called by save at confirmation modal in the invite modal
   * This will not destroy the uploaded file. This only closes the invite modal,
   * and clearing the importid, history table data, and invites count
   */
  const onInviteModalClose = useCallback(async () => {
    setImportId(null)
    setImportHistoryTableData(emptyImportHistoryTableData)
    setInvitesCount(usersNotSubmittedAssessmentCount)
    setOpenInviteModal(false)
  }, [
    setImportId,
    emptyImportHistoryTableData,
    setImportHistoryTableData,
    usersNotSubmittedAssessmentCount,
    setInvitesCount,
    setOpenInviteModal,
  ])

  /**
   * Send invites to the users that were imported or send invites to all users if no file was imported
   */
  const onRequestSendInvites = useCallback(
    async (inviteAll) => {
      axios
        .post(inviteUrl, {
          authenticity_token: window.authenticity_token,
          import_id: importId,
          invite_all: inviteAll,
          invite_count: invitesCount,
        })
        .then((response) => {
          if (
            response.data?.activity_logs?.data &&
            response.data?.activity_logs?.meta
          ) {
            setActivityLogsTableData(response.data.activity_logs.data)
            setActivityLogsTableMeta(response.data.activity_logs.meta)
          }
        })
    },
    [inviteUrl, importId],
  )

  /**
   * Adds the selected filters to the cumulative report URL as query params
   */
  const cumulativeReportUrlWithParams = useMemo(() => {
    const url = new URL(cumulativeReportUrl, window.location.origin)
    url.search = new URLSearchParams({
      filters: JSON.stringify(selectedFilters),
    }).toString()
    return url.toString()
  }, [cumulativeReportUrl, selectedFilters])

  return (
    <Container
      direction="column"
      css={
        // Fixes page padding on mobile.
        isNarrow
          ? null
          : {
              padding: spacings.main_padding,
              alignItems: 'flex-start',
            }
      }>
      <Container
        direction={`${isNarrow ? 'column' : 'row'}`}
        alignment={`${isNarrow ? 'start' : 'center'}`}
        css={{
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: spacings.grid_gap_basis_num * 3,
        }}>
        <h2>LEADR™ Index Overview</h2>
        <Container
          css={
            isNarrow
              ? { width: '100%', position: 'relative' }
              : { position: 'relative' }
          }
          direction={`${isNarrow ? 'column' : 'row'}`}
          alignment={`${isNarrow ? 'start' : 'center'}`}>
          <Button
            as={ButtonComponentType.LINK}
            target="_blank"
            variant={Variant.LINK}
            href={assessmentUrl}
            startIcon={
              <EyeIcon
                css={{
                  minWidth: 14,
                  minHeight: 14,
                  marginRight: spacings.grid_gap_basis_num / 2,
                  path: { fill: colors.links.blue },
                }}
              />
            }>
            LEADR™ Index assessment preview
          </Button>
          <Button
            variant={Variant.PRIMARY}
            onClick={() => setOpenInviteModal(true)}>
            Send Invites
          </Button>
          <DotMenu
            css={isNarrow ? { right: 0, position: 'absolute' } : null}
            menuItems={[
              {
                label: 'View activity log',
                onClick: () => setOpenActivityLogModal(true),
              },
            ]}></DotMenu>
        </Container>
      </Container>
      <Container
        direction={`${isNarrow ? 'column' : 'row'}`}
        css={isNarrow ? narrowWidgetsStyle : fullWidgetsStyle}>
        <PeopleWidget
          numPeople={initialCounters.participants}
          title="Assessment participants"
        />
        <InquiriesWidget
          requestSize={initialCounters.retake_inquiries}
          title="Retake inquiries"
        />
      </Container>
      <MembershipFilters
        css={{
          width: '100%',
        }}
        hideSearch
        filters={selectedFilters}
        filterOptions={filters}
        updateFilters={updateFilters}
        showAllFilters
        currentCount={counters.participants}
        initialCount={initialCounters.participants}
        displayCount
        actionNode={
          <Button
            as={ButtonComponentType.LINK}
            variant={Variant.SECONDARY}
            href={cumulativeReportUrlWithParams}
            disabled={!counters.participants}
            css={{
              marginTop: 16,
            }}>
            View LEADR™ results
          </Button>
        }
      />
      <Table
        css={{
          width: '100%',
          // Fixes janky experience when sorting to few results.
          minHeight: isNarrow ? '100vh' : 0,
          // Makes the table controls stacked on mobile
          '.GojiCustomTable-header .section-header, .GojiCustomTable-header .section-header .secondary-nodes':
            isNarrow
              ? {
                  flexDirection: 'column',
                  alignItems: 'start',
                  gap: spacings.grid_gap_basis_num,
                }
              : { flexDirection: 'row' },
        }}
        title="Assessment participants"
        tableData={data}
        tableMeta={meta}
        onPageChange={loadPage}
        selectedFilters={tableFilters}
        onOrderChange={({ sort_col, sort_dir }) => {
          updateOrder(
            sort_col,
            sort_dir === 'asc' || sort_dir === 'desc' ? sort_dir : 'asc',
          )
        }}
        secondaryActionNode={() => (
          <Switch
            css={{
              gap: spacings.grid_gap_basis,
            }}
            controlName="inquiry_toggle"
            defaultChecked={false}
            uncheckedText="All"
            checkedText="Only with inquiries"
            onChange={(e) => {
              const onlyInquiries = e.target.checked
              const updatedFilters = _.cloneDeep(selectedFilters)
              updatedFilters.show = onlyInquiries ? 'inquiry' : null
              updateFilters(updatedFilters)
            }}
          />
        )}
      />
      <LeadrAssessmentInviteModal
        modalProps={{
          isOpen: openInviteModal,
          onRequestClose: onRequestCloseInviteModal,
        }}
        importHistoryTableData={importHistoryTableData}
        onUploadCsvImportFile={uploadCsvImportFile}
        destroyUploadedImportFile={destroyUploadedImportFile}
        onSendInvites={onRequestSendInvites}
        invitesCount={invitesCount}
        onInviteModalClose={onInviteModalClose}
        exampleUrl={exampleUrl}
        csvImportErrors={csvImportErrorMessage}
      />
      <ActivityLogModal
        largeTitle
        css={{
          '&.small': {
            maxWidth: 696,
            width: 696,
            backgroundColor: colors.backgrounds.page,
          },
        }}
        isOpen={openActivityLogModal}
        onRequestClose={() => setOpenActivityLogModal(false)}
        onSave={() => setOpenActivityLogModal(false)}
        tableComponentProps={{
          tableData: activityLogsTableData,
          tableMeta: activityLogsTableMeta,
        }}
        cancelButton="close"
      />
    </Container>
  )
}
