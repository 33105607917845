import { css } from '@emotion/react'
import { fonts, spacings } from 'stylesheets/theme'

export const SModal = css({
  '&.large': {
    maxWidth: '100vh',
    width: 767,
  },
})

export const SMobileModal = css({
  '&.large': {
    maxWidth: '100vh',
    width: 'auto',
  },
})

export const SModalContent = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})

export const SBadgeIcon = css({
  '--square': '281px',
  height: 'var(--square)',
  width: 'var(--square)',
  aspectRatio: 1.0,
  marginTop: 2 * spacings.grid_gap_basis_num,
  marginBottom: 3 * spacings.grid_gap_basis_num,
})

export const SHeading = css({
  fontSize: 24,
  lineHeight: '30px',
  fontWeight: 700,
  fontFamily: fonts.poppins,
  marginBottom: spacings.grid_gap_basis_num,
})

export const SParagraph = css({
  textAlign: 'center',
  marginBottom: 4 * spacings.grid_gap_basis_num,
})

export const SShareOnLinkedInIcon = css({
  height: 14,
  width: 14,
  marginRight: 0.5 * spacings.grid_gap_basis_num,
})

export const SCloseButtonMobile = css({
  marginTop: 2 * spacings.grid_gap_basis_num,
})
