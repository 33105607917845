import { css } from '@emotion/react'
import Button, { ButtonComponentType, Variant } from 'components/Button'
import CsvFileInput from 'components/CsvFileInput'
import Modal, { IModalProps, Size } from 'components/Modal'
import Table, { ITableProps } from 'components/Table'
import { Paragraph } from 'components/Typography'
import { useEffect, useState } from 'react'
import { colors, spacings } from 'stylesheets/theme'

const newRowHighlightCss = css({
  'tr.new td': {
    background: colors.green_bg_very_light,
  },
})

interface ILeadrAssessmentInviteModalProps {
  modalProps: Omit<IModalProps, 'children' | 'size' | 'onSave'>
  // Executes when the user uploads a CSV file. This reads the CSV file and returns a number of users that will be invited, and a number of error rows
  onUploadCsvImportFile: (file: File) => void
  destroyUploadedImportFile: (onAllow: () => void) => void
  importHistoryTableData: ITableProps
  onSendInvites: (inviteAll: boolean) => void
  invitesCount: number
  onInviteModalClose: () => void
  exampleUrl: string
  csvImportErrors?: string
}

export default function LeadrAssessmentInviteModal({
  modalProps,
  onUploadCsvImportFile,
  destroyUploadedImportFile,
  importHistoryTableData,
  onSendInvites,
  invitesCount,
  onInviteModalClose,
  exampleUrl,
  csvImportErrors = '',
}: ILeadrAssessmentInviteModalProps): JSX.Element {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [csvImportFile, setCsvImportFile] = useState(null)

  useEffect(() => {
    if (csvImportFile) {
      onUploadCsvImportFile(csvImportFile)
    }
  }, [csvImportFile])

  return (
    <Modal
      {...modalProps}
      size={Size.large}
      largeTitle
      title="Send invites"
      css={{
        '&.large': {
          maxWidth: 1066,
        },
        backgroundColor: colors.backgrounds.page,
      }}
      submitButton="send"
      cancelButton="close"
      onSave={() => setOpenConfirmationModal(true)}>
      <Paragraph
        css={{
          marginBottom: spacings.grid_gap_basis_num * 2,
        }}>
        Email invitation will go out organization-wide upon confirmation, unless
        you upload a CSV of desired recipients using the sample format provided.
        Download&nbsp;
        <Button
          as={ButtonComponentType.LINK}
          variant={Variant.LINK}
          href={exampleUrl}
          css={{
            display: 'inline-block',
          }}>
          this example
        </Button>
        &nbsp;to see the required format.
      </Paragraph>
      <CsvFileInput
        name={'assessment_invites[file]'}
        error={csvImportErrors}
        cachedData={csvImportFile}
        initialValue={null}
        additionalComponentsWhenUnmodified={null}
        onChangeDocument={(file) => setCsvImportFile(file)}
        onConfirmDeleteDocument={destroyUploadedImportFile}
      />
      <Table css={newRowHighlightCss} {...importHistoryTableData} />
      <Modal
        isOpen={openConfirmationModal}
        largeTitle
        title="Please confirm the recipients"
        submitButton="confirm"
        cancelButton="Back to editing"
        size={Size.large}
        onRequestClose={() => setOpenConfirmationModal(false)}
        //if there is csvImportFile, that means we only want to invite users in the csv file. Otherwise, invite all users
        onSave={() => {
          onSendInvites(!csvImportFile)
          setOpenConfirmationModal(false)
          onInviteModalClose()
          window.flash(
            'Invitations sent to ' + invitesCount + ' users',
            'success',
          )
        }}>
        <Paragraph>
          Your invitation will be sent to the <b>{invitesCount}</b> users listed
          in the uploaded CSV file. Users will receive the invitation once they
          confirm.
        </Paragraph>
      </Modal>
    </Modal>
  )
}
