import React from 'react'
import { SectionTitle } from 'components/Typography'
import './SectionHeader.scss'

interface SectionHeaderProps {
  children: JSX.Element | string
}

export default function SectionHeader({
  children,
}: SectionHeaderProps): JSX.Element {
  return (
    <div className="section-header">
      <div className="header-left">
        <SectionTitle>{children}</SectionTitle>
      </div>
    </div>
  )
}
