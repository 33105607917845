import React, { useState, useEffect } from 'react'

import Modal, { IModalProps, Size } from 'components/Modal'

import useSendReminderFormState from './SendReminderFormState'

import LocationIcon from 'images/icons/location.svg'

import './SendReminder.scss'

import { IEventCall } from 'types'
import Container from 'components/Container'
import { Paragraph } from 'components/Typography'
import { colors, spacings } from 'stylesheets/theme'
import { convertUTCToLocalRange } from 'javascripts/general'
import { css } from '@emotion/react'

interface IReminderEmailProps extends IModalProps {
  event: IEventCall
}

const defaultModalTextStyle = css({
  fontFamily: 'Lato',
  fontSize: 13,
  fontWeight: 400,
  lineHeight: '22px',
  textAlign: 'left',
})

const SendReminder: React.FC<IReminderEmailProps> = ({ event }) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const { done, sendReminder, submitDisabled } = useSendReminderFormState({
    eventId: event.id,
    hosts: event.hosts,
  })

  useEffect(() => {
    if (done) {
      setModalOpen(false)
    }
  }, [done, setModalOpen])

  return (
    <>
      <Modal
        css={{
          '& .mindr-modal-title': {
            color: colors.text.text_5,
          },
        }}
        isOpen={isModalOpen}
        title="Remind users about this event"
        subtitle={
          <>
            A reminder will be sent to&nbsp;
            <b>{event.invited_users_count}</b>
            &nbsp;users who have been notified about the event but have not yet
            registered.
          </>
        }
        addLineAfterSubtitle
        size={Size.large}
        className="send-reminder-modal"
        onRequestClose={() => setModalOpen(false)}
        onSave={sendReminder}
        cancelButton="No reminders"
        submitButton="Send Remindesr"
        isSubmitDisabled={submitDisabled}>
        <Container css={{ flexDirection: 'column', alignItems: 'flex-start' }}>
          <Paragraph>Here are the details for this events:</Paragraph>
          <h1 css={{ color: colors.blue, fontWeight: 700 }}>{event.title}</h1>
          {event.datetime && (
            <h2
              css={{
                color: colors.blue,
              }}>
              {convertUTCToLocalRange(event.datetime, event.duration)}
            </h2>
          )}
          <Container
            css={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: spacings.grid_gap_basis_num / 2,
            }}>
            {event.location_in_person && (
              <Container
                css={{
                  gap: spacings.grid_gap_basis_num / 2,
                }}>
                <LocationIcon
                  css={{
                    width: 9.41,
                    height: 14,
                    path: {
                      fill: colors.blue,
                    },
                  }}
                />
                <span css={defaultModalTextStyle}>
                  Address: {event.location_in_person}
                </span>
              </Container>
            )}
            {event.location_virtual && (
              <Container
                css={{
                  gap: spacings.grid_gap_basis_num / 2,
                }}>
                <span css={defaultModalTextStyle}>Dial-in link:</span>
                <a
                  href={event.location_virtual}
                  className="link"
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                  rel="noreferrer">
                  {event.location_virtual}
                </a>
              </Container>
            )}
          </Container>
          {event.hosts.map((host) => (
            <Container
              key={host.name}
              css={{
                gap: spacings.grid_gap_basis_num / 2,
              }}>
              <img
                css={{
                  width: 30,
                  height: 30,
                }}
                src={host.logo}
              />
              <span css={defaultModalTextStyle}>
                By <b>{host.name}</b>
              </span>
            </Container>
          ))}
        </Container>
      </Modal>
      <button
        className="menu-link not-styled-button send-reminder-button"
        onClick={() => setModalOpen(true)}>
        Send RSVP Reminder
      </button>
    </>
  )
}

export default SendReminder
