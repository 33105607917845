import React, { useMemo, useRef, useState } from 'react'
import Axios from 'axios'
import _ from 'lodash'
import produce, { enableES5 } from 'immer'

enableES5()
// assets
import DownloadIcon from '../../images/icons/download.svg'

//styles
import './Reports.scss'

// components
import Table, { IMainTableFilters, ITableProps } from '../Table'
import Input from 'components/Input'

async function fetchData(url: string, params: Partial<IFilters>) {
  try {
    const response = await Axios.get(url, { params })
    return response.data
  } catch (error) {
    window.flash('Something went wrong!', 'alert')
  }
}

// interfaces
type TReportType = {
  label: string
  value: string
  exportAllAvailable: boolean
  subTitle?: string
}

interface IReportsProps extends ITableProps {
  communityType: 'Cohort' | 'Community' | null
  reportTypes: TReportType[]
}

interface IFilters extends IMainTableFilters {
  type: string
  community_type: 'Cohort' | 'Community' | null
  'filter[active_only]': string | undefined
  'filter[include_without_surveys]': boolean | undefined
  page?: number
}

const Reports: React.FC<IReportsProps> = ({
  tableData,
  tableMeta,
  communityType,
  reportTypes,
  ...props
}) => {
  const [data, setData] = useState(tableData)
  const [meta, setMeta] = useState(tableMeta)
  const [selectedFilters, setSelectedFilters] = useState<IFilters>({
    community_type: communityType,
    'filter[active_only]': 'true',
    'filter[include_without_surveys]': undefined,
    type: 'engagement',
    ...props.selectedFilters,
  })

  const loadData = async (filters: Partial<IFilters>, page?: number) => {
    const normalizedFilters = { ...filters }
    if (page) {
      normalizedFilters.page = page + 1
    }

    const { data, meta, selectedFilters } = await fetchData(
      tableMeta.url,
      normalizedFilters,
    )
    setData((prev) =>
      produce(prev, (draft) => {
        draft.rows = data.rows
        draft.paginator = data.paginator
      }),
    )
    setSelectedFilters((currentValue) => ({
      ...currentValue,
      ...selectedFilters,
    }))
    setMeta(meta)
  }

  const { current: loadDataDebounced } = useRef(_.debounce(loadData, 300))

  const changeFilters = (filter) => {
    setSelectedFilters((currentValue) => {
      const newValue = { ...currentValue, ...filter }
      loadDataDebounced(newValue)
      return newValue
    })
  }

  let exportLink = `${meta.url.replace('.json', '.csv')}?type=${
    selectedFilters.type
  }`

  if (selectedFilters['filter[*]']) {
    exportLink = `${exportLink}&filter[*]=${selectedFilters['filter[*]']}`
  }

  if (selectedFilters['filter[active_only]']) {
    exportLink = `${exportLink}&filter[active_only]=${selectedFilters['filter[active_only]']}`
  }

  if (selectedFilters['filter[include_without_surveys]']) {
    exportLink = `${exportLink}&filter[include_without_surveys]=${selectedFilters['filter[include_without_surveys]']}`
  }

  const selectedReportType: TReportType = useMemo(
    () => _.find(reportTypes, { value: selectedFilters.type }),
    [selectedFilters, reportTypes],
  )

  return (
    <div className="reports-index">
      <div className="type-block">
        <h3>Report Type</h3>
        <Input
          name="type"
          type="select"
          options={reportTypes}
          value={selectedFilters.type}
          onChange={(reportType) => {
            // Always default "only active users" to true
            const newSelectedFilters = {
              ...reportType,
              'filter[active_only]': true,
            } as unknown as IFilters
            setSelectedFilters(newSelectedFilters)
            loadData(newSelectedFilters)
          }}
        />
      </div>
      <div className="section-header">
        <div className="header-left">
          <p className="section-title">{selectedReportType?.label}</p>
          {meta.exportable && (
            <>
              <a href={exportLink} className="link">
                <DownloadIcon />
                Export Table
              </a>
              {selectedReportType?.exportAllAvailable && (
                <a href={`${exportLink}&export_all=true`} className="link">
                  <DownloadIcon />
                  Export All Survey Data
                </a>
              )}
            </>
          )}
        </div>
        <div className="header-right">
          {meta.togglable_filter === 'active_only' && (
            <div className="input-component-wrapper active-only togglable-filter">
              <div className="switch-wrapper">
                <p className="off">All Users</p>
                <Input
                  type="switch"
                  name="filter[active_only]"
                  wrapperClassName="switch-component-wrapper"
                  checked={!!selectedFilters['filter[active_only]']}
                  onChange={(inputData) => {
                    changeFilters({
                      type: selectedFilters.type,
                      ...inputData,
                    } as IFilters)
                  }}
                />
                <p className="on">Only Active Users</p>
              </div>
            </div>
          )}
          {meta.togglable_filter === 'survey_only' && (
            <div className="input-component-wrapper surveys-only togglable-filter">
              <div className="switch-wrapper">
                <p className="off">All Events</p>
                <Input
                  type="switch"
                  name="surveys_only"
                  wrapperClassName="switch-component-wrapper"
                  checked={!selectedFilters['filter[include_without_surveys]']}
                  onChange={(inputData) => {
                    changeFilters({
                      type: selectedFilters.type,
                      'filter[include_without_surveys]':
                        !inputData['surveys_only'],
                    } as IFilters)
                  }}
                />
                <p className="on">Only With Surveys</p>
              </div>
            </div>
          )}
          {meta.searchable && (
            <Input
              name="filter[*]"
              type="search"
              wrapperClassName="search"
              onChange={(searchField) =>
                changeFilters({
                  type: selectedFilters.type,
                  ...searchField,
                } as IFilters)
              }
              value={selectedFilters['filter[*]']}
              placeholder={
                selectedFilters.type === 'events' ||
                selectedFilters.type === 'calls'
                  ? 'Search Titles'
                  : 'Search Report'
              }
            />
          )}
        </div>
      </div>
      {selectedReportType?.subTitle && (
        <p className="subtitle">{selectedReportType?.subTitle}</p>
      )}
      <Table
        tableData={data}
        tableMeta={meta}
        onPageChange={(page: number) => {
          loadData(selectedFilters, page)
        }}
        onOrderChange={changeFilters}
        selectedFilters={selectedFilters}
      />
      {['events', 'calls', 'events_cohorts', 'community_past_events'].includes(
        selectedFilters.type,
      ) && (
        <p className="comment">
          * Satisfaction rates are from 2 (very satisfied) to -2 (very
          dissatisfied)
        </p>
      )}
    </div>
  )
}

export default Reports
